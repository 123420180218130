<template>
  <div class="view-books">
    <advanced-data-table
      action="books/get"
      :filters.sync="filters"
      :headers="headers"
      ref="table"
      :items="books"
      v-bind="options"
      show-search
    >
      <template #cell(title)="{item}">
        <router-link
          :to="{ name: 'ViewBook', params: { id: item.id } }"
          class="text-dark"
          ><strong>{{
            item.book_type === "print" ? item.title + " (print)" : item.title
          }}</strong></router-link
        >
        <p class="text-muted m-0">{{ item.subtitle }}</p>
      </template>
      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1"
            >{{ $t(item.status) }}
          </b-badge>
        </h5>
      </template>

      <!-- Publication date -->
      <template #cell(publication_date)="{item}">
        {{
          item.publication_date ? formatDate(item.publication_date) : " None"
        }}
      </template>

      <!-- Actions -->
      <template #actions="{item}">
        <router-link :to="{ name: 'EditBook', params: { id: item.id } }">
          <b-button size="sm" variant="primary" :disabled="iSPublishing(item)">
            Edit
          </b-button>
        </router-link>
        <b-button
          @click="duplicate(item)"
          size="sm"
          class="ml-1"
          :disabled="iSPublishing(item)"
          >{{ $t("duplicate") }}
        </b-button>
      </template>
      <!-- Custom filters -->
      <template #filters>
        <!-- Status filter -->
        <adt-filter :label="$t('status')">
          <b-select
            :options="addAllOption(statuses)"
            v-model="filters.status"
            size="sm"
          ></b-select>
        </adt-filter>
        <!-- Type filter -->
        <adt-filter :label="$t('type')">
          <b-select
            :options="addAllOption(types)"
            v-model="filters.book_type"
            size="sm"
          ></b-select>
        </adt-filter>
      </template>
    </advanced-data-table>
  </div>
</template>

<script>
import AdvancedDataTable from "../../../components/advanced-data-table";
import AdtFilter from "@/components/core/adt-filter";
import BookStatuses from "@/fixtures/book-statuses";
import BookTypes from "@/fixtures/book-types";

export default {
  name: "view-books",
  components: {
    AdvancedDataTable,
    AdtFilter
  },
  props: {
    books: Array,
    options: Object
  },
  data() {
    return {
      page: 1,
      limit: 10,
      filters: {
        status: null
      },
      headers: [
        {
          key: "title",
          label: "name",
          sortable: true
        },
        "status",
        {
          key: "publication_date",
          label: "publication_date",
          sortable: true
        },
        {
          key: "epub_isbn",
          label: "ISBN",
          sortable: false
        },
        "actions"
      ],
      statuses: BookStatuses.map(status => ({
        text: this.$t(status),
        value: status
      })),
      types: BookTypes.map(type => ({
        text: this.$t(type),
        value: type
      }))
    };
  },
  methods: {
    iSPublishing(book) {
      return book.status === "publishing";
    },
    splitKeywords(keywords) {
      return keywords.split(",");
    },
    async duplicate({ title, id }) {
      if (confirm(this.$t("duplicate-book", { title }))) {
        this.ebpLoader(true);

        try {
          const res = await this.$store.dispatch("books/duplicate", id);
          this.$router.push({
            name: "EditBook",
            params: {
              id: res.data.id
            }
          });
        } catch (err) {
          this.ebpLoader(false);
          console.error(err);
        }
      }
    }
  }
};
</script>

<style></style>
